import React from 'react';
import { Translator } from '../I18n';

const Cards = () => {
  return (
    <>
      <main id="main">
        <section className="services">
          <div className="container">
            <div className="row">
              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch"
                data-aos="fade-up"
              >
                <div className="icon-box icon-box-pink">
                  <div className="icon">
                    <i className="bx bx-calendar-plus"></i>
                  </div>
                  <h4 className="title">
                    <a href="$">
                      <Translator path="cards.controle" />
                    </a>
                  </h4>
                  <p className="description">
                    <Translator path="cards.controle_description" />
                  </p>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="icon-box icon-box-cyan">
                  <div className="icon">
                    <i className="bx bx-file"></i>
                  </div>
                  <h4 className="title">
                    <a href="$">
                      <Translator path="cards.organization" />
                    </a>
                  </h4>
                  <p className="description">
                    <Translator path="cards.organization_description" />
                  </p>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="icon-box icon-box-green">
                  <div className="icon">
                    <i className="bx bx-tachometer"></i>
                  </div>
                  <h4 className="title">
                    <a href="$">
                      <Translator path="cards.access" />
                    </a>
                  </h4>
                  <p className="description">
                    <Translator path="cards.access_description" />
                  </p>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="icon-box icon-box-blue">
                  <div className="icon">
                    <i className="bx bx-world"></i>
                  </div>
                  <h4 className="title">
                    <a href="$">
                      <Translator path="cards.all_time" />
                    </a>
                  </h4>
                  <p className="description">
                    <Translator path="cards.all_time_description" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Cards;
