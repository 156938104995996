import React from 'react';
import { Translator } from '../I18n';

const Hero = () => {
  return (
    <>
      {/* <!-- ======= Hero Section ======= --> */}
      <section
        id="hero"
        className="d-flex justify-cntent-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval="5000"
        >
          {/* <!-- Slide 1 --> */}
          <div className="carousel-item active">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">
                <span style={{ fontFamily: 'Heebo' }}>
                  <Translator path="hero.welcome" />
                </span>{' '}
                <span style={{ color: '#fff', fontFamily: 'Heebo' }}>
                  Igreja nas nuvens
                </span>
              </h2>
              <p className="animate__animated animate__fadeInUp">
                <span style={{ fontFamily: 'Zilla Slab', fontSize: '18px' }}>
                  <Translator path="hero.jeremiah" />
                </span>
              </p>
              <a
                href="https://gestor.igrejanasnuvens.com.br/"
                className="btn-get-started animate__animated animate__fadeInUp"
              >
                <Translator path="hero.login" />
              </a>
            </div>
          </div>

          {/* <!-- Slide 2 --> */}
          <div className="carousel-item">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">
                <span style={{ fontFamily: 'Heebo' }}>
                  <Translator path="hero.welcome" />
                </span>{' '}
                <span style={{ color: '#fff', fontFamily: 'Heebo' }}>
                  Igreja nas nuvens
                </span>
              </h2>
              <p className="animate__animated animate__fadeInUp">
                <span style={{ fontFamily: 'Zilla Slab', fontSize: '18px' }}>
                  <Translator path="hero.ecclesiastes" />
                </span>
              </p>

              <a
                href="https://gestor.igrejanasnuvens.com.br/"
                className="btn-get-started animate__animated animate__fadeInUp"
              >
                <Translator path="hero.login" />
              </a>
            </div>
          </div>

          {/* <!-- Slide 3 --> */}
          <div className="carousel-item">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">
                <span style={{ fontFamily: 'Heebo' }}>
                  <Translator path="hero.welcome" />
                </span>{' '}
                <span style={{ color: '#fff', fontFamily: 'Heebo' }}>
                  Igreja nas nuvens
                </span>
              </h2>
              <p className="animate__animated animate__fadeInUp">
                <span style={{ fontFamily: 'Zilla Slab', fontSize: '18px' }}>
                  <Translator path="hero.malachi" />
                </span>
              </p>
              <a
                href="https://gestor.igrejanasnuvens.com.br/"
                className="btn-get-started animate__animated animate__fadeInUp"
              >
                <Translator path="hero.login" />
              </a>
            </div>
          </div>

          <a
            className="carousel-control-prev"
            href="#heroCarousel"
            role="button"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon bx bx-chevron-left"
              aria-hidden="true"
            ></span>
          </a>

          <a
            className="carousel-control-next"
            href="#heroCarousel"
            role="button"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon bx bx-chevron-right"
              aria-hidden="true"
            ></span>
          </a>
        </div>
      </section>
      {/* <!-- End Hero --> */}
    </>
  );
};

export default Hero;
