import React from 'react'
import { Link } from 'react-router-dom'
import facebook from '../../assets/facebook_108044.png'
import instagram from '../../assets/instagram_108043.png'
import youtube from '../../assets/youtube_108041.png'
import I18n, { Translator } from '../I18n'

export const Footer = () => {
  return (
    <>
      <footer
        id="footer"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="500"
      >
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>
                  {' '}
                  <Translator path="footer.language.h4" />
                </h4>
                <div>
                  <I18n />
                </div>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>
                  <Translator path="footer.services.h4" />
                </h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="$">
                      <Translator path="footer.services.a.1" />
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="$">
                      {' '}
                      <Translator path="footer.services.a.2" />
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{' '}
                    <a href="$">
                      {' '}
                      <Translator path="footer.services.a.3" />
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="$">Pague sua mensalidade</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-contact">
                <h4>
                  {' '}
                  <Translator path="footer.contact.h4" />
                </h4>
                <p>
                  Demerval Barbosa Moreira
                  <br />
                  210B - São Pedro - 25956-045
                  <br />
                  Teresópolis - RJ <br />
                  <br />
                  <strong>Telefone:</strong> +55 21 99283-7415
                  <br />
                </p>
              </div>

              <div className="col-lg-3 col-md-6 footer-info">
                <h4>Igreja nas nuvens</h4>
                <p>
                  <Translator path="footer.church.p" />
                </p>
                <div className="social-links mt-4">
                  <a href="https://www.youtube.com/watch?v=n1U94T7B8BU">
                    <img
                      src={youtube}
                      alt="youtube"
                      style={{
                        marginTop: -9,
                        width: 38,
                        height: 38,
                        marginLeft: -1,
                      }}
                    />
                  </a>

                  <a href="https://www.facebook.com/Igreja-nas-Nuvens-267567781760161">
                    <img
                      src={facebook}
                      alt="facebook"
                      style={{
                        marginTop: -9,
                        width: 38,
                        height: 38,
                        marginLeft: -1,
                      }}
                    />
                  </a>
                  <a href="https://www.instagram.com/igrejanasnuvens/">
                    <img
                      src={instagram}
                      alt="instagram"
                      style={{
                        marginTop: -10,
                        width: 39,
                        height: 39,
                        marginLeft: -1.8,
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright{' '}
            <strong>
              <span>Igreja nas nuvens</span>
            </strong>
            <br /> <Link to="/privacy">Política de privacidade</Link>
            {' | '}
            <Link to="/terms">Termos de serviços</Link>
          </div>
        </div>
      </footer>

      {/* <!-- End Footer --> */}
    </>
  )
}
