// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    header: {
      login: 'Login',
      contato: 'Contato',
      free: 'Quero uma consultaria gratis',
    },
    hero: {
      login: 'Login',
      welcome: 'Bem vindo a',
      ecclesiastes:
        ' E, quando Deus concede riquezas e bens a alguém e o capacita a desfrutá-los, a aceitar a sua sorte e a ser feliz em seu trabalho, isso é um presente de Deus. Eclesiastes 5:19',
      malachi:
        'Tragam o dízimo todo ao depósito do templo, para que haja alimento em minha casa. Ponham-me à prova", diz o Senhor dos Exércitos, "e vejam se não vou abrir as comportas dos céus e derra­mar sobre vocês tantas bênçãos que nem terão onde guardá-las. Malaquias 3:10',
      jeremiah:
        'Porque sou eu que conheço os planos que tenho para vocês, diz o Senhor, planos de fazê-los prosperar e não de causar dano, planos de dar a vocês esperança e um futuro. Jeremias 29:11',
    },
    cards: {
      controle: 'Controle',
      controle_description:
        'Tenha o controle das entradas e saídas de forma simples e objetiva',
      organization: 'Organização',
      organization_description:
        'Organize as finanças da sua congregação de forma simples e rapidamente',
      access: 'Acesso ilimitado',
      access_description:
        ' Acesso ilimitado a plataforma. Você tem acesso a todo o conteúdo sem limitações',
      all_time: 'Sempre online',
      all_time_description:
        'Garantia de 99,99% online. seus dados seguros e acessível de qualquer lugar',
    },
    features: {
      feature: 'Características',
      options:
        'Igreja nas nuvens, feito por pessoas que compartilha das mesmas ideas, ajudando igrejas a crescer com a organização das finanças. Com Igreja nas nuvens você tem acesso de forma rápida como esta a gestão da sua igreja',
      ministration: {
        h3: 'Saiba quais pessoas fazem parte do seu ministério',
        p: 'Com a Igreja nas nuvens cadastre seus membros',
        check01:
          'Saiba a idade ministério que faz parte cada membro da sua congregação',
        check02:
          'Conheça aqueles que sempre investe no reino através do dízimos',
      },
      finances: {
        h3: 'Tenha acesso rápido as finanças',
        p: 'Acesso de qualquer lugar como esta as finanças',
        check:
          'Você tem um relatório de como anda suas entradas assim pode saber onde deve atuar para melhora os rendimentos e poder investir no crescimento do reino',
      },
      report: {
        h3: 'Relatório anual e mensal',
        p: 'Tenha acesso a relatório anual e mensal das entradas ou saídas',
        check:
          'Com Igreja nas nuvens você tem acesso a relatórios de forma simples e objetiva como anda sua gestão',
      },
    },
    footer: {
      church: {
        p: 'Faça parte de nossas redes sociais, curta, dê a sua opinião e compartilhe informações com os seus amigos e seguidores.',
      },
      services: {
        h4: 'Nossos serviços',
        a: {
          1: 'Desenvolvimento web',
          2: 'Gestão de produtos',
          3: 'Marketing',
        },
      },
      contact: {
        h4: 'Contato',
      },
      language: {
        h4: 'Idioma',
      },
    },
  },
};
