import React, { useEffect } from 'react';
import { BrowserRouter, Route, useLocation } from 'react-router-dom';
import { Footer } from '../components/Footer';
import LandingPage from '../components/LandingPage';
import { Privacy, Terms } from '../legal';
import Home from './Home';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      {/* <Header /> */}
      <Route exact path="/" component={Home} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/LandingPage" component={LandingPage} />
      <Footer />
    </BrowserRouter>
  );
}
