import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import { Features } from '../Features';
import { Translator } from '../I18n';
import Youtube from '../Youtube';

const phone = '+5521966705559';
const appOrigin = 'https://igrejanasnuvens.com.br/';
const mensagem = `
Ola,
%0a
Tenho interesse no sistema igreja nas nuvens
%0a
${appOrigin}
`;

const advancedMatching = { em: 'ignasnuvens@gmail.com' };
const options = {
  autoConfig: true,
  debug: false,
};

const LandingPage = () => {
  React.useEffect(() => {
    console.log('ReactPixel');
    ReactPixel.init('931469207734895', advancedMatching, options);
  }, []);
  return (
    <>
      {/* <!-- ======= LandingPage Section ======= --> */}
      <div className="container">
        <div className="d-flex justify-content-center">
          <section>
            <div
              style={{
                borderBottom: 'solid 1px #e6e6e6',
              }}
            >
              <div
                className="row"
                style={{
                  display: 'flex',
                  marginTop: '20px',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <a
                  className="col-md-2"
                  href="https://igrejanasnuvens.com.br/"
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: 21,
                    padding: '7px',
                    color: '#444',
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: '10px',
                  }}
                >
                  Igreja nas nuvens
                </a>
                <div
                  class="col-md-3"
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <a
                    href="https://cadastro.igrejanasnuvens.com.br/?id=pagina%20quero%20uma%20consulta%20gratuita"
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <span
                      style={{
                        padding: '7px',
                        borderRadius: '5px',
                        border: 'solid 1px #00a8ff',
                      }}
                    >
                      <Translator path="header.free" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                style={{
                  marginTop: '20px',
                  marginBottom: '10px',
                }}
                className="row"
              >
                <p
                  className="text-center"
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: 19,
                    color: '#222',
                  }}
                >
                  <strong>Igreja nas nuvens</strong> é um sistema criado,
                  pensado e elaborado por uma empresa cristã com o intuito de
                  oferecer um serviço de excelência para que as igrejas possam
                  avançar financeiramente à partir da organização em suas
                  finanças.
                  <p
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    O sistema oferece ainda:{' '}
                    <strong>
                      Cadastro de membros, Agenda, Suporte e Atualizações.
                    </strong>
                  </p>
                </p>
              </div>
            </div>

            <div className=" d-flex justify-content-center">
              <div className="col-md-10 align-items-center">
                <section className="services">
                  <div className="row">
                    <Youtube embedId="HPrvRo8wpgs" />
                  </div>
                </section>
                <div className="d-flex justify-content-center align-items-center">
                  <a
                    href={`https://api.whatsapp.com/send/?phone=${phone}&text=${mensagem}`}
                    className="btn-primary col-md-10 btn-lg d-flex justify-content-center mt- text-center"
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: 32,
                      padding: 12,
                      color: '#fff',
                      borderRadius: 15,
                    }}
                  >
                    Fale com nosso consultor agora
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Features />

      {/* <!-- End LandingPage --> */}
    </>
  );
};

export default LandingPage;
