import React from 'react';
import Cards from '../components/Cards';
import { Features } from '../components/Features';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Youtube from '../components/Youtube';
// import LandingPage from '../components/LandingPage';

// const advancedMatching = { em: 'ignasnuvens@gmail.com' };
// const options = {
//   autoConfig: true,
//   debug: true,
// };

function Home() {
  // não funciona em modo de desenvolvimento, em produção funciona como deveria
  // useEffect(() => {
  //   ReactPixel.init('931469207734895', advancedMatching, options);
  //   ReactPixel.pageView();
  //   console.log('ReactPixel');
  // }, []);

  return (
    <div className="App">
      <Header />
      <Hero />
      {/* <LandingPage /> */}
      <main id="main">
        <section className="services">
          <div className="container">
            <div className="row">
              <Youtube
                embedId="n1U94T7B8BU"
                youtube="row-md-6 col-lg-6 align-items-stretch"
                fade="fade-up"
              />
              <Youtube
                embedId="HPrvRo8wpgs"
                youtube="row-md-6 col-lg-6 align-items-stretch"
                fade="fade-up"
              />
            </div>
          </div>
        </section>
      </main>
      <Cards />
      <Features />
    </div>
  );
}

export default Home;
