import React from 'react';
import { Translator } from '../I18n';

export const Features = () => {
  return (
    <>
      {/* <!-- ======= Features Section ======= --> */}
      <section
        className="container d-flex justify-content-center"
        style={{
          paddingTop: 90,
          paddingBottom: 90,
        }}
      >
        <div className="col-lg-10" data-aos="fade-up">
          <div
            className="text-center"
            style={{
              fontFamily: 'Roboto',
              fontSize: 16,
              paddingBottom: 30,
            }}
          >
            <h2>{/* <Translator path="features.feature" /> */}</h2>

            <p>
              <Translator path="features.options" />
            </p>
          </div>

          <div className="row" data-aos="fade-up">
            <div className="col-md-5">
              <img
                src="assets/img/features-1.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-md-7 pt-4">
              <h3>
                {' '}
                <Translator path="features.ministration.h3" />
              </h3>
              <p className="fst-italic">
                <Translator path="features.ministration.p" />
              </p>
              <ul>
                <li>
                  <i className="bi bi-check"></i>{' '}
                  <Translator path="features.ministration.check01" />
                </li>
                <li>
                  <i className="bi bi-check"></i>{' '}
                  <Translator path="features.ministration.check02" />
                </li>
              </ul>
            </div>
          </div>

          <div className="row" data-aos="fade-up">
            <div className="col-md-5 order-1 order-md-2">
              <img
                src="assets/img/features-2.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-md-7 pt-5 order-2 order-md-1">
              <h3>
                {' '}
                <Translator path="features.finances.h3" />
              </h3>
              <p className="fst-italic">
                <Translator path="features.finances.p" />
              </p>
              <ul>
                <li>
                  <i className="bi bi-check"></i>
                  <Translator path="features.finances.check" />
                </li>
              </ul>
            </div>
          </div>

          <div className="row" data-aos="fade-up">
            <div className="col-md-5">
              <img
                src="assets/img/features-4.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-md-7 pt-5 order-2 order-md-1">
              <h3>
                {' '}
                <Translator path="features.report.h3" />
              </h3>
              <p className="fst-italic">
                <Translator path="features.report.p" />
              </p>
              <ul>
                <li>
                  <i className="bi bi-check"></i>
                  <Translator path="features.report.check" />
                </li>
              </ul>
            </div>
          </div>
          <br />
        </div>
      </section>
    </>
  );
};
