import PropTypes from 'prop-types';
import React from 'react';
import './style.css';

const Youtube = ({ embedId, youtube, fade }) => (
  // div para quando tiver mais de um video
  <div className={youtube} data-aos={fade}>
    <div className=" mb-2">
      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  </div>
);

Youtube.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default Youtube;
