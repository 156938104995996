import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../img/logo.png';
import { Translator } from '../I18n';

const Header = () => {
  return (
    <>
      <header
        id="header"
        className="fixed-top d-flex align-items-center header-transparent header"
        style={{ backgroundColor: 'rgba(30, 67, 86, 0.4)' }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div>
            <h1 className="text-light">
              <Link to="/">
                <img
                  src={Logo}
                  alt="logo"
                  style={{ height: 80, width: 80, marginTop: 10 }}
                />
                <span
                  style={{ color: '#fff', fontFamily: 'Heebo', fontSize: 25 }}
                >
                  Igreja nas nuvens
                </span>
              </Link>
            </h1>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a
                  className="active"
                  href="https://gestor.igrejanasnuvens.com.br/"
                >
                  <span
                    style={{ color: '#fff', fontSize: 17, fontWeight: 'bold' }}
                  >
                    <Translator path="header.login" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="active"
                  href="https://cadastro.igrejanasnuvens.com.br/?id=pagina%20de%20contato"
                >
                  <span
                    style={{ color: '#fff', fontSize: 17, fontWeight: 'bold' }}
                  >
                    <Translator path="header.contato" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="active"
                  href="https://cadastro.igrejanasnuvens.com.br/?id=pagina%20quero%20uma%20consulta%20gratuita"
                >
                  <span
                    style={{
                      color: '#fff',
                      fontSize: 15,
                      fontWeight: 'bold',
                      border: '1px solid #fff',
                      padding: '5px 10px',
                      borderRadius: '5px',
                    }}
                  >
                    <Translator path="header.free" />
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
