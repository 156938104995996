import React from 'react';
import { useTranslation } from 'react-i18next';

import { BrasilFlag, EuaFlag } from '../../assets';
import Flag from './Flag';
// import Tex from './Tex';

const I18n = () => {
  const { i18n } = useTranslation();

  function handleChangeLanguage(language) {
    i18n.changeLanguage(language);
  }

  const selectedLanguage = i18n.language;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: -25,
      }}
    >
      <div
        className="social-links mt-4"
        style={{
          width: 50,
          height: 50,
        }}
      >
        <a href="/">
          <div style={{ marginTop: -6, width: 32, height: 32, marginLeft: 2 }}>
            <Flag
              image={BrasilFlag}
              isSelected={selectedLanguage === 'pt-BR'}
              onClick={() => handleChangeLanguage('pt-BR')}
            />
          </div>
        </a>
      </div>
      <div
        className="social-links mt-4"
        style={{
          width: 50,
          height: 50,
        }}
      >
        <a href="/">
          <div style={{ marginTop: -6, width: 32, height: 32, marginLeft: 2 }}>
            <Flag
              image={EuaFlag}
              isSelected={selectedLanguage === 'en-US'}
              onClick={() => handleChangeLanguage('en-US')}
            />
          </div>
        </a>
      </div>
    </div>
  );
};

export default I18n;
