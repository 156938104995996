// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    header: {
      login: 'Login',
      contato: 'Contact',
      free: 'I want a consultation for free',
    },
    hero: {
      login: 'Login',
      welcome: 'Welcome to',
      ecclesiastes:
        'Moreover, when God gives someone wealth and possessions, and the ability to enjoy them, to accept their lot and be happy in their toil—this is a gift of God. Ecclesiastes 5:19',
      malachi:
        'Bring the whole tithe into the storehouse, that there may be food in my house. Test me in this,” says the LordAlmighty, “and see if I will not throw open the floodgates of heaven and pour out so much blessing that there will not be room enough to store it. Malachi 3:10',
      jeremiah:
        'For I know the plans I have for you,” declares the Lord, “plans to prosper you and not to harm you, plans to give you hope and a future. Jeremiah 29:11',
    },
    cards: {
      controle: 'Control',
      controle_description:
        'Have control of inputs and outputs simply and objectively',
      organization: 'Organization',
      organization_description:
        'Organize the finances of your congregation simply and quickly',
      access: 'Unlimited access',
      access_description:
        ' Unlimited access to the platform.You have access to all content without limitations',
      all_time: 'Always online',
      all_time_description:
        '99.99% warranty online.Your insurance and accessible data from anywhere',
    },
    features: {
      feature: 'Features',
      options:
        'Church in the clouds, made by people sharing the same ideas, helping churches grow with the organization of finance.With church in the clouds you have access quickly as this management of your church',
      ministration: {
        h3: 'Find out what people are part of your ministry',
        p: 'With the church in the clouds Register your members',
        check01:
          'Know the ministry age that is part of each member of its congregation',
        check02: 'Meet those who always invest in the kingdom through tithes',
      },
      finances: {
        h3: 'Have Quick Access Finance',
        p: 'Access from anywhere like this finances',
        check:
          'You have a report on how your entries can thus know where to act to improve income and be able to invest in the growth of the kingdom',
      },
      report: {
        h3: 'Annual and monthly report',
        p: 'Get access to Annual and Monthly Report of Inputs or Outputs',
        check:
          'With church in the clouds you have access to reports in a simple and objective way how your management is',
      },
    },
    footer: {
      church: {
        p: 'Be part of our social networks, short, give your opinion and share information with your friends and followers.',
      },
      services: {
        h4: 'Our services',
        a: {
          1: 'Web development',
          2: 'Product Management',
          3: 'Marketing',
        },
      },
      contact: {
        h4: 'Contact',
      },
      language: {
        h4: 'Language',
      },
    },
  },
};
